import { createMachine, assign } from "xstate";
import { DataAnalysisContext, DataAnalysisEvent } from "./types";
import { dataAnalysisMachineOptions } from "./utils";

export const dataAnalysisStateMachine = createMachine<
  DataAnalysisContext,
  DataAnalysisEvent
>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QBECGAXVACAggO1QBsBPWAS1iwAVUYA6AWQHsIxDc8Is1MsBlNmADG6MkzwBidACdUZPFgC2LNgG0ADAF1EoAA5Nyo8TpAAPRAEYArAA4ALHQBsAdivqbATgDM6jy48ATAA0IMSIdjbOdOrOXo7eAd4eVvEAvqkhPNj4RKQU1LRgjCrs+FxZ-IIiYpKwVehKJRraSCD6hjUm5ggWXjZedClWztYWAeruNlYhYQhezjZ0No5WfbZWdnHzVumZGNkEJOSUNPTMrKWc3PuVhMJGtfVYEDd16M0m7WQPXZbzjoNeo5en1nOpQTNEAF+nQAnZ1HZEUCLJt5rsQBUckd8qciuc2FgACqyeTyKASCDiIryABuTAA1kVMYc8idCsULkSSXgyQhaUwhBgas0Pq0vj9Wt1rPYnK53N5fP5gqFELZFlYrNDYkD5mN0czcscCmcSly5Dy8OSwNJpExpHRdIQMAAzO2KOgG7Fsk2c4nm3n8wUPEVaT4Gb6dSVQizOBxeTVw9QBYYaxyQhCOGJ0Dx2GNeQIWfoxRz6m5Y1nGvGmgAyqAARmxCGSJARFGBGhdRXpwxLQN0NhYnNCvPmbIkApnnOmrL0lknApm1XYAhZS7xy0bcRyCbWG4Qm5aJDSyGAAO5YaRwACuhHQsC7bR7kb7iBGDhjCc1-UcuvTI6ssoLCkIxeHYsQ5muByGji7L4uwu6Ns2daoEI9IPuKz5mK+KJ0B+ATJsOP4jOmdgpHQy4LDYiqOBE9iQRw0HekUABKYD6NIDQAKI2naEjIah6FPsYUYIPhFgAn48QWOohYuF4Fh-psdDOMCMaxoWmpeAE9EbjB9BoLAAAWdZMKg0hcLifEoWhoZikJeC-D0FjJE4SYxn4MQ2M5f6zhY1igc4fjJH5djpBkIB4Co8CtJ6Fa4mGHTCS+CARAEynxEmrhuNJwxpiqPR+NEsQLB4ziuB4QWruFsWbrBpplNcvACHc1RJY+iUOSJIKLPJCI5uoGw5isJGBeRHgIjGi4BPJ2nVWWLK1T6BJ+qSloJRGbVSn4AEuDOqLLKV7jTjCmb2JsuYePYs17OuC16VWnIIfuZLrb2WEIKs6jpeNARZTJA02OmwIOBRyxlWV6iOMmOl3UxdCsexXE8dIr2Yd001WB4soJnYP5rDYgP5Zmg7xldjjLJEMkw4xlYeqgRkmWZFmFKjm2WEmaW9TOwwLi4eWzCOAJqVMLi9GB+ahWFQA */
    id: "Data Analysis Page",
    context: {
      model: undefined,
      xDataSet: [],
      yDataSet: [],
      modelName: "default model name",
    },
    states: {
      "Model And Data Selection": {
        on: {
          "train model": {
            target: "Model Training",
            cond: "model and data set selected",
          },
          "select model": {
            target: "Model And Data Selection",
            internal: true,
            actions: assign({ model: (_, event) => event.model }),
          },
          "select data set": {
            target: "Model And Data Selection",
            internal: true,
            actions: assign({ xDataSet: (_, event) => event.dataSet }),
          },
        },
      },

      "Model Training": {
        invoke: {
          src: "createModel",
          onDone: {
            target: "Model Labelling",
            actions: assign({
              modelResults: (_, event) => event.data,
            }),
            description: `The model creation will send a post request to the backend which will return the information required for the dashboard page`,
          },
          onError: "Report Error",
        },
      },

      "Model Labelling": {
        on: {
          "name model": {
            target: "Model Labelling",
            internal: true,
            actions: assign({ modelName: (_, event) => event.modelName }),
          },

          "view results": {
            target: "Dashboard Page",
            cond: "unique label selected",
          },

          back: {
            target: "Model And Data Selection",
            actions: "reset state",
          },
        },
      },

      "Report Error": {
        on: {
          back: {
            target: "Model And Data Selection",
            actions: "reset state",
          },
        },
      },

      "Dashboard Page": {
        on: {
          back: {
            target: "Model And Data Selection",
            actions: "reset state",
          },
        },
      },
    },

    initial: "Model And Data Selection",
  },
  dataAnalysisMachineOptions
);
