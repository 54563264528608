import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CircularStatic from "../../../components/animated_components/CircularLoading";
import MQTTApi from "../../../apis/mqtt/MQTTApi";
import Button from "../../../components/buttons/Button";

type Step = {
  label: string;
};

let activeStep = 0;

const stepsFactory = (numberOfFilesToDownload: number): Step[] => {
  if (numberOfFilesToDownload === -1) {
    return [
      {
        label: "Scanning the uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Ready for download",
      },
    ];
  } else if (numberOfFilesToDownload === 2) {
    return [
      {
        label: "Scanning the first uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Scanning the second uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Ready for download",
      },
    ];
  } else if (numberOfFilesToDownload === 3) {
    return [
      {
        label: "Scanning the first uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Scanning the second uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Scanning the third uploaded file",
      },
      {
        label: "Matching pixels",
      },
      {
        label: "Ready for download",
      },
    ];
  } else {
    return [];
  }
};

export default function VerticalLinearStepperComponent(props: {
  onRestartButtonClicked: () => void;
  numberOfFilesToDownload?: number;
  onBackButtonClicked: () => void;
}) {
  // these are the two variables which are used to calculate the displayed percentage
  const [currentValue, setCurrentValue] = React.useState(0);
  const [currentTotal, setCurrentTotal] = React.useState(100);

  // depending on how many files were uploaded, the steps factory will return different components
  let steps: Step[] = [];
  if (props.numberOfFilesToDownload) {
    steps = stepsFactory(props.numberOfFilesToDownload);
  }

  React.useEffect(() => {
    const messageBus = new MQTTApi();

    messageBus.onConnect(() => {
      messageBus.subscribeClient("sequence_matching_progress/1", () => {});
      messageBus.subscribeClient("sequence_matching_progress/2", () => {});
      messageBus.subscribeClient(
        "sequence_matching_progress/tasks_completed",
        () => {}
      );

      // listening to the first loop in the sequence matching algorithm
      messageBus.onMessage("sequence_matching_progress/1", (message: string) => {
        const { current, total } = JSON.parse(message);
        updateLinearProgress(current, total);
      });

      // listening to the second loop in the sequence matching algorithm
      messageBus.onMessage("sequence_matching_progress/2", (message: string) => {
        const { current, total } = JSON.parse(message);
        updateLinearProgress(current, total);
      });

      // listening for changes in the active step
      messageBus.onMessage(
        "sequence_matching_progress/tasks_completed",
        (message: string) => {
          const tasksCompleted = parseInt(JSON.parse(message)["tasks completed"]);
          if (Number.isNaN(tasksCompleted)) {
            activeStep = steps.length;
            updateLinearProgress(currentValue, currentTotal);
          } else {
            if (tasksCompleted > activeStep) {
              activeStep = tasksCompleted;
              updateLinearProgress(currentValue, currentTotal);
            }
          }
        }
      );
    });

    return () => {
      messageBus
        .unsubscribeClient("sequence_matching_progress/1")
        .unsubscribeClient("sequence_matching_progress/2")
        .disconnectClient();
    };
  }, []);

  const updateLinearProgress = (current: number, total: number) => {
    setCurrentValue(current);
    setCurrentTotal(total);
  };

  const handleRestartButtonClicked = () => {
    activeStep = 0;
    props.onRestartButtonClicked();
  };

  // get all the plots from the results folder at the end of the analysis
  const handleDownload = () => {
    if (props.numberOfFilesToDownload) {
      let numberOfFilesToDownload = props.numberOfFilesToDownload;
      if (numberOfFilesToDownload < 0) {
        numberOfFilesToDownload = 1;
      }

      for (let i = 0; i < numberOfFilesToDownload + 1; i++) {
        fetch(
          `https://wizappresourcemanager-production.up.railway.app/v1/sequencematching/${i}`
        )
          .then((response) => response.blob())
          .then((blob) => {
            const downloadLink = document.createElement("a");
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `plot${i}.png`;
            downloadLink.click();
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
          });
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 400, minHeight: "100vh" }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel
              optional={
                index === steps.length - 1 && activeStep >= steps.length - 1 ? (
                  <div
                    onClick={handleDownload}
                    className="text-tertiary hover:cursor-pointer"
                  >
                    Download Results
                  </div>
                ) : null
              }
            >
              <p className="paragraph-text">{step.label}</p>
            </StepLabel>
            <StepContent>
              <CircularStatic value={currentValue} total={currentTotal} />
            </StepContent>
          </Step>
        ))}
        {activeStep >= steps.length - 1 ? (
          <Button
            inner={"Restart"}
            onClick={handleRestartButtonClicked}
            className="mt-12"
          />
        ) : (
          <Button
            inner={"Back"}
            onClick={props.onBackButtonClicked}
            className="mt-12"
          />
        )}
      </Stepper>
    </Box>
  );
}
